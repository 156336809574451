// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";
window.jQuery = $;
window.$ = $;

require("bootstrap");
// require("select2");
import 'select2'; 
import 'bootstrap-select'; 
import './lib/bootstrap-datepicker.min.js'; 
import Isotope from 'isotope-layout';
import intlTelInput from 'intl-tel-input';
const FrenchTranslation=require("select2/src/js/select2/i18n/fr");
const Translation=require("select2/src/js/select2/translation");
Translation._cache["./i18n/fr"]=FrenchTranslation;
Translation._cache["fr"]=FrenchTranslation;

require("select2Plugins");
// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
// require("./select2.optgroupSelect.js");

// Remove this demo code, that's only here to show how the .env file works!
// if (process.env["HELLO"]) {
//   console.log(`Hello ${process.env.HELLO}!`);
// }

!function(a){a.fn.datepicker.dates.fr={days:["dimanche","lundi","mardi","mercredi","jeudi","vendredi","samedi"],daysShort:["dim.","lun.","mar.","mer.","jeu.","ven.","sam."],daysMin:["d","l","ma","me","j","v","s"],months:["janvier","février","mars","avril","mai","juin","juillet","août","septembre","octobre","novembre","décembre"],monthsShort:["janv.","févr.","mars","avril","mai","juin","juil.","août","sept.","oct.","nov.","déc."],today:"Aujourd'hui",monthsTitle:"Mois",clear:"Effacer",weekStart:1,format:"dd/mm/yyyy"}}(jQuery);

$(document).ready(function() {

    var txt_domaines = lang == 'es' ? 'Tematicas' : 'Domaines';
    var txt_lieux = lang == 'es' ? 'Lugares' : 'Lieux';
    var txt_noresult = lang == 'es' ? 'No hay resultados' : 'Aucun résultat';

    var buttonText=  {
        today: {'fr': 'Auj','es': 'Hoy'},
        month: {'fr': 'Mois','es': 'Mes'},
        week: {'fr': 'Semaine','es': 'Semana'},
       day: {'fr': 'Jour','es': 'Día'},
      };

    // $('.creer-offre').on('submit', function(){
    //     if($('[name=offres_themes]:checked').length == 0){
    //         $('.alert-themes').removeClass('d-none') ;
    //         return false ;
    //         $('body').animate({
    //             scrollTop: $(".creer-offre").offset().top
    //         }, 2000);
    //     }
    //     else{
    //         $('.alert-themes').addClass('d-none') ;
    //         var _return = true;
    //         $('[name=offres_themes]:checked').each(function(){
    //             var val = $(this).val();
    //             if($('[name=secteurs_'+val+']:checked').length == 0){
    //                 $('.alert-themes').removeClass('d-none') ;
                    
    //                 $('body').animate({
    //                     scrollTop: $(".creer-offre").offset().top
    //                 }, 2000);

    //                 _return = false ;


    //             }
    //         });
    //         return _return ;
    //     }
    // });

    

    // $('.collapse').collapse();

    /** Smoothscroll on anchor links */
    $('a[href^="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
            && 
            location.hostname == this.hostname
        ) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function() {
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { 
                        return false;
                    } else {
                        $target.attr('tabindex','-1'); 
                        $target.focus(); 
                    };
                });
            }
        }
    });



    if($('.annonces').length > 0){
        var grid = document.querySelector('.annonces');
        var iso = new Isotope( grid, {
            columnWidth: '.grid-sizer'
        });
        $('.filter-button-group').on('click', 'button', function() { // To filter your isotope.
            var filterValue = $(this).attr('data-filter');
            iso.arrange({
                filter: filterValue // All grid items
             });
            // $grid.isotope({ filter: filterValue });
        });
    }
    

    $('.selectpicker').selectpicker();

    $(document).on('click', '.navbar-toggler-icon', function(){
        $('.menu').toggleClass('show');
        $('body').toggleClass('has-menu-opened');
    });

      const inputPhone = document.querySelector(".input-phone");
      if(inputPhone){
        intlTelInput(inputPhone, {
            onlyCountries: ["fr", "es"],
            localizedCountries : {
                'es': 'España',
            },
            initialCountry: "auto",
            geoIpLookup: function(callback) {
              $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
                var countryCode = (resp && resp.country) ? resp.country : "fr";
                callback(countryCode);
              });
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js"
          });
    
      }
    
    /**
     * Show tel annonce
     */
    $(document).on('click', 'a[data-action="show-tel"]', function(){
        var annonce_id = $(this).data('annonce-id') ;
        $.post('ajax-controller.php', {
            get_annonce_phone : true,
            annonce_id : annonce_id,
          })
          .done(function( res ) {
            
            console.log(res);
           
          });
  
          return false; 
      });


  $('.language-selector').on( 'change', function(){
     // var current_url = $(this).data('current-url');
      var current_url = window.location.href;
      var language = $(this).find('option:selected').data('language');
      var query_string_separator = current_url.indexOf('?') != -1 ? '&' : '?' ;
      window.location.href = current_url + query_string_separator + 'lang='+language ;
  });

  var txt_not_allowed ="Ton pack ne permet pas d'accéder à cette fonctionnalité" ;

  // Presentation slider
  if (!localStorage.hidePresentation || +localStorage.hidePresentation !== 1) {
      setTimeout(function(){
          $('#presentation-slider-modal').modal('show');
      }, 3000);
  }

  $('#presentation-slider-modal').on('hidden.bs.modal', function(e){
      localStorage.hidePresentation = 1
  });


  // ALert CGU ont évolué depuis le 25/11/2020
  if (!localStorage.hideAlertCgu || +localStorage.hideAlertCgu !== 1) {
      setTimeout(function(){
          $('#alert-cgu').removeClass('d-none');
      }, 1000);
  }

  $('#alert-cgu').on('closed.bs.alert', function () {
      localStorage.hideAlertCgu = 1
    })
  $('#alert-cgu').on('click', 'a', function () {
      localStorage.hideAlertCgu = 1;
      $("#alert-cgu").alert('close')
    })
 
 

  $('.nav-link-disabled')
      .attr('title', txt_not_allowed)
      .tooltip('enable')
      .on('click', function(){
      return false ;
  });
  $('.feature-disabled')
      .attr('title', txt_not_allowed)
      .tooltip('enable')
      .on('click', function(){
      return false ;
  });
 

 
  $('.toggle-floating-menu').on('click', function(e){
      var parent = $(this).parents('.floating-menu-wrapper') ;
      var icon = $(this).find('i.fa') ;
      if(parent.hasClass('closed')){
          parent.removeClass('closed');
          icon.removeClass('fa-chevron-right')
          icon.addClass('fa-chevron-left');
          return false;
      }
      if(!parent.hasClass('closed')){
          parent.addClass('closed');
          icon.removeClass('fa-chevron-left')
          icon.addClass('fa-chevron-right');
          return false;
      }
  });
 

//   $('#footer-full-content').load('https://www.gopy.eu #footer-full-content >*')

   //cas special email
  $('input[type="email"]').on('keyup', function(e) {
         
      var formGroup = $(this).parents('.form-group') ;
      var feedback = formGroup.find('.invalid-feedback');
      feedback.remove();

      var invalid_feedback = {
        'fr' : '&uarr; Email non valide',
        'es' : '&uarr; Email no es valid'
    };


      if( !validateEmail(e.target.value) ) {
          $(this).addClass('is-invalid');
          $(this).after('<div class="invalid-feedback">'+invalid_feedback[lang]+'</div>');
      }
      else {
          $(this).removeClass('is-invalid').addClass('is-valid');
          formGroup.find('.invalid-feedback').remove();
      }
  });

//validation phone
//  $('input.candidats_telephone').on('keyup', function(e) {

//       var formGroup = $(this).parents('.form-group') ;
//       var feedback = formGroup.find('.invalid-feedback');

//       var invalid_feedback = {
//           'fr' : '&uarr; Veuillez entrer un numéro de téléphone au format 0********* ou 0* ** ** ** **',
//           'es' : '&uarr; Veuillez entrer un numéro de téléphone au format 0********* ou 0* ** ** ** **'
//       };

//           feedback.remove();
//           if( !validatePhone(e.target.value) ) {
//               $(this).addClass('is-invalid');
//               $(this).parents('.form-group').append('<div class="invalid-feedback d-block">'+invalid_feedback[lang]+'</div>');
//           }
//           else {
//               $(this).removeClass('is-invalid').addClass('is-valid');
//               formGroup.find('.invalid-feedback').remove();
//           }
  
     
//   });


 
  $('[data-toggle="tooltip"]').tooltip() ;

  
  /*
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var hash = $(e.target).attr('href');
      if (history.pushState) {
        history.pushState(null, null, hash);
      } else {
        location.hash = hash;
      }
    });
    
    var hash = window.location.hash;
    if (hash) {
      $('.nav-link[href="' + hash + '"]').tab('show');
    }

    */

  //modal partage offre
  $('#genericModal').on('show.bs.modal', function (e) {
    let trigger = $(e.relatedTarget) ;
      var modal_action = trigger.data('modal-action');
      if(modal_action == 'share-by-email'){
          let offre_id = trigger.data('offre-id') ;
          let offre_name = trigger.data('offre-name') ;
          let user_email = trigger.data('user-email') ;
          let user_name = trigger.data('user-name') ;
          let offre_type = trigger.data('offre-type') || 'offre' ;

          $(this).find('.modal-title').html('Partager l\'offre <em>'+offre_name+'</em> par email :');
          $(this).find('.modal-footer').remove();

          let modal_body = `<form action="#" class="clearfix share-by-email">
          <div class="form-group">
              <label for="your_name">Ton nom</label>
              <input type="text" class="form-control" id="your_name" name="your_name" placeholder="Ton nom" value="${user_name}"> 
              <input type="hidden" id="offre_id" name="offre_id"  value="${offre_id}"> 
              <input type="hidden" id="offre_type" name="offre_type"  value="${offre_type}"> 
          </div>
          <div class="form-group">
              <label for="share_email">Adresse email de ton ami(e)</label>
              <input autofocus type="email" class="form-control" id="share_email" name="share_email" aria-describedby="emailHelp" placeholder="Adresse email de ton ami(e)">
              <small id="emailHelp" class="form-text text-muted">Indique ici l'email de la personne avec laquelle tu souhaites partager l'offre.</small>
          </div>
          <button type="submit" class="btn btn-primary float-right">Partager</button>
          </form>`;

          $(this).find('.modal-body').html(modal_body);
          $('#share_email').focus();
      }
    }) ;

    //submit du form partage par email
    $(document).on('submit', '.share-by-email', function(){
      var your_name = $('#your_name').val();
      var share_email = $('#share_email').val();
      var offre_id = $('#offre_id').val();
      var offre_type = $('#offre_type').val();


      $.post('ajax-controller.php', {
          share_by_email : true,
          your_name : your_name,
          share_email : share_email,
          offre_id : offre_id,
          offre_type : offre_type
        })
        .done(function( res ) {

          setTimeout(function(){
              if(res.status == 'success'){
                  $('.modal-body').append('<div class="clearfix alert mt-3 alert-success">'+res.message+'</div>');

                  setTimeout(function(){
                      $('#genericModal').modal('hide')
                  }, 1500);
                 
              }
              else{
                  $('.modal-body').append('<div class="alert mt-3 alert-warning">'+res.message+'</div>');
              }
            }, 1000);
         
        });

        return false; 
    });


  // Ajout offre à la valise
  $('.add-to-suitcase').on('click', function(){

    var selector = $(this);

    var user_id = $(this).data('user-id');
    var offre_id = $(this).data('offre-id');


    $.post('ajax-controller.php', {
        add_to_suitcase : true,
        user_id : user_id,
        offre_id : offre_id
      })
      .done(function( res ) {

        console.log(res);

        setTimeout(function(){
            if(res.status == 'add'){
                //this_selector.after('<div class="alert mt-3 alert-success">'+res.message+'</div>');
                selector.removeClass('btn-outline-dark');
                selector.addClass('btn-outline-success');
                selector.html('<i class="fa fa-check"></i> Ajouté à ma liste !');

               
            }
            else if(res.status == 'remove'){
                //selector.after('<div class="alert mt-3 alert-success">'+res.message+'</div>');
                selector.not('.wishlist-list-group-item__remove').removeClass('btn-outline-success');
                selector.not('.wishlist-list-group-item__remove').addClass('btn-outline-dark');
                selector.not('.wishlist-list-group-item__remove').html('<i class="fa fa-heart"></i> Ajouter à ma liste');

                //si dans le contexte de la homepage (liste des offres dans la wishlist)
                if(selector.parents('.wishlist-list-group-item').length > 0){
                    selector.parents('.wishlist-list-group-item').remove();
                }

               
            }
            else{
                //selector.after('<div class="alert mt-3 alert-warning">'+res.message+'</div>');
                console.log('error');
                console.log(res);
            }

            //reinit l'icone du bouton
            selector.find('.fa-heart').removeClass('d-none');
            selector.find('.fa-spin').addClass('d-none');


          }, 500);
       
      });

      return false; 

  }) ;

  // Ajout offre à la valise
  $('.add-to-suitcase-real').on('click', function(){

      var selector =   $('.add-to-suitcase');
      var this_selector =   $(this);

      $(this).find('.fa-suitcase').addClass('d-none');
      $(this).find('.fa-spin').removeClass('d-none');

      var user_id = $(this).data('user-id');
      var offre_id = $(this).data('offre-id');


      $.post('ajax-controller.php', {
          add_to_suitcase : true,
          user_id : user_id,
          offre_id : offre_id
        })
        .done(function( res ) {

          setTimeout(function(){
              if(res.status == 'success'){
                  // this_selector.after('<div class="alert mt-3 alert-success">'+res.message+'</div>');
                  $('.modal-body').append('<div class="alert mt-3 alert-success">'+res.message+'</div>');
                  selector.removeClass('btn-secondary');
                  //selector.addClass('btn-success');
                  selector.replaceWith('<a href="/espace-candidat/suitcase" class=" btn btn-success"><i class="fa fa-check"></i> Ajouté à ma valise !</a>');
                  //selector.html('<i class="fa fa-check"></i> Ajouté à ma valise !');

                  setTimeout(function(){
                      $('#addToSuitcaseModal').modal('hide')
                  }, 5000);

                 
              }
              else{
                  // this_selector.after('<div class="alert mt-3 alert-warning">'+res.message+'</div>');
                  $('.modal-body').append('<div class="alert mt-3 alert-warning">'+res.message+'</div>');
              }

              //reinit l'icone du bouton
              selector.find('.fa-suitcase').removeClass('d-none');
              selector.find('.fa-spin').addClass('d-none');

              $('#nav-4').load('//'+window.location.hostname+'/espace-candidat/offre?offre_id='+offre_id+' #nav-4 > *');
              $('#nav-5').load('//'+window.location.hostname+'/espace-candidat/offre?offre_id='+offre_id+' #nav-5 > *');


            }, 1000);
         
        });

        return false; 

  }) ;


  // Ajout offre à la wishlist
  $(document).on('click', '.add-to-wishlist:not(.is-in-wishlist)', function(){

      var selector =   $(this);
      var i =   $(this).find('.fa-star');

      $(this).find('.fa-star').addClass('d-none');
      $(this).find('.fa-spin').removeClass('d-none');

     
      var user_id = $(this).data('user-id');
      var offre_id = $(this).data('offre-id');


      $.post('ajax-controller.php', {
          add_to_wishlist : true,
          user_id : user_id,
          offre_id : offre_id
        })
        .done(function( res ) {

          setTimeout(function(){
              if(res.status == 'add'){
                  i.removeClass('far');
                  i.addClass('fa');
                  i.addClass('text-secondary');
                  i.attr('title', 'Ajouté à mes favoris !');
                  selector.addClass('is-in-wishlist');
                  $('span.add').addClass('d-none');
                  $('span.added').removeClass('d-none');
                  selector.attr('href', './wishlist');
                 
              }
              else if(res.status == 'remove'){
                i.removeClass('fa');
                i.addClass('far');
                i.addClass('text-white');
                i.attr('title', '');
                selector.removeClass('is-in-wishlist');
                $('li[data-offre-id="'+res.offre_id+'"]').remove();
                $('span.add').removeClass('d-none');
                $('span.added').addClass('d-none');

              }
              else{
                  //selector.after('<div class="alert mt-3 alert-warning">'+res.message+'</div>');
                  console.log('error');
                  console.log(res);
              }

              //reinit l'icone du bouton
              selector.find('.fa-star').removeClass('d-none');
              selector.find('.fa-spin').addClass('d-none');


            }, 500);
         
        });

        return false; 

  }) ;

  // Ajout ANNONCE à la wishlist
  $(document).on('click', '.add-to-wishlist-annonce:not(.is-in-wishlist)', function(){

      var selector =   $(this);
      var i =   $(this).find('.fa-star');

      $(this).find('.fa-star').addClass('d-none');
      $(this).find('.fa-spin').removeClass('d-none');

     
      var user_id = $(this).data('user-id');
      var annonce_id = $(this).data('annonce-id');


      $.post('ajax-controller.php', {
          add_to_wishlist_annonce : true,
          user_id : user_id,
          annonce_id : annonce_id
        })
        .done(function( res ) {

          setTimeout(function(){
              if(res.status == 'add'){
                  i.removeClass('far');
                  i.addClass('fa');
                  i.attr('title', 'Ajouté à ma liste !');
                  selector.addClass('is-in-wishlist');
                  selector.find('span').text('Ajouté à ma liste');
                 
              }
              else if(res.status == 'remove'){
                i.removeClass('fa');
                i.addClass('far');
                i.attr('title', '');
                selector.removeClass('is-in-wishlist');
                $('li[data-annonce-id="'+res.annonce_id+'"]').remove();

              }
              else{
                  //selector.after('<div class="alert mt-3 alert-warning">'+res.message+'</div>');
                  console.log('error');
                  console.log(res);
              }

              //reinit l'icone du bouton
              selector.find('.fa-star').removeClass('d-none');
              selector.find('.fa-spin').addClass('d-none');


            }, 500);
         
        });

        return false; 

  }) ;

  //Enregistremebn commande pack
  $('.order-record').on('click', function(){

      $(this).find('.fa-check').addClass('d-none');
      $(this).find('.fa-spin').removeClass('d-none');

      var user_id = $(this).data('user-id');
      var pack_id = $(this).data('pack-id');
      var offre_id = $(this).data('offre-id') || false;


      $.post('ajax-controller.php', {
          order_record : true,
          user_id : user_id,
          pack_id : pack_id,
          
        })
        .done(function( res ) {

          if(res.status == 'success'){
              $('.order-record').after('<div class="alert alert-success">'+res.message+'</div>');


              var redirectUrl = './' ;
              if(false != offre_id){
                  redirectUrl += '?offre_id='+offre_id
              }

              setTimeout(function(){
                  window.location.href = redirectUrl;
                }, 3000);
          }
          else{
              $('.order-record').after('<div class="alert alert-danger">'+res.message+'</div>');
          }
         
        });

        return false; 

  }) ;

  //select 2 & dropdowns
  $('.select2-single').select2({
      theme: 'bootstrap',
      language: {
          "noResults": function(){
              return txt_noresult;
          }
      }
  });

  // $('.categorie-dispositif-select2').select2({
  //     theme: 'bootstrap',
  //  width: 'resolve' ,
  //     placeholder: {
  //         id: '-1', // the value of the option
  //         text: 'Type'
  //       }
  // });
  // $('.country-select2').select2({
  //     theme: 'bootstrap',
  //  width: 'resolve' ,
  //     placeholder: {
  //         id: '-1', // the value of the option
  //         text: 'Pays'
  //       }
  // });

  // $('.categorie-dispositif-select2').select2({
  //     theme: 'bootstrap',
  //  width: 'resolve' ,
  //     placeholder: {
  //         id: '-1', // the value of the option
  //         text: 'Type'
  //       }
  // });

  $.fn.select2.amd.require(["optgroup-data", "optgroup-results"], 
  function (OptgroupData, OptgroupResults) {
          $('.categorie-dispositif-select2').select2({
          dataAdapter: OptgroupData,
          resultsAdapter: OptgroupResults,
          closeOnSelect: true,
          theme: 'bootstrap',
           width: 'resolve' ,
          minimumResultsForSearch : -1,
          placeholder: {
              id: '-1', // the value of the option
              text: 'Type'
          },
          language: {
              "noResults": function(){
                  return txt_noresult;
              }
          }
      }); 
  });

  
  $.fn.select2.amd.require(["optgroup-data", "optgroup-results"], 
  function (OptgroupData, OptgroupResults) {
          $('.country-select2').select2({
          dataAdapter: OptgroupData,
          resultsAdapter: OptgroupResults,
          closeOnSelect: true,
          theme: 'bootstrap',
           width: 'resolve' ,
          placeholder: {
              id: '-1', // the value of the option
              text: txt_lieux
          },
          language: {
              "noResults": function(){
                  return txt_noresult;
              }
          }
      }); 
  });
  

  var highlighted_themes = [] ;

  $.fn.select2.amd.require(["optgroup-data", "optgroup-results"], 
  function (OptgroupData, OptgroupResults) {
          $('.theme-select2').select2({
          dataAdapter: OptgroupData,
          resultsAdapter: OptgroupResults,
          closeOnSelect: true,
          theme: 'bootstrap',
           width: 'resolve' ,
          placeholder: {
              id: '-1', // the value of the option
              text: txt_domaines
          },
          language: {
              "noResults": function(){
                  return txt_noresult;
              }
          }
      }); 

      $('.theme-select2').find('optgroup.selected-custom').each(function(){
          highlighted_themes.push($(this).attr('label'));
      });
      $('.theme-select2').find('optgroup:not(.selected-custom)').each(function(){
          $(this).find('option:selected').each(function(){
              highlighted_themes.push(String($(this).text()));
          });
        
      });

      // console.log(highlighted_themes);

      $('.theme-em').each(function(){
          var txt = $(this).text() ;
          if( $.inArray(txt,highlighted_themes ) > -1){
              $(this).html('<span style="background-color:Gold;color:var(--dark)">'+txt+'</span>') ;
          }
      });


  });

 

 

  $('.duree-select2').select2({
       theme: 'bootstrap',
        width: 'resolve' ,
       minimumResultsForSearch : -1,
       placeholder: {
           id: '-1', // the value of the option
           text: 'Durée'
         },
         language: {
             "noResults": function(){
                 return txt_noresult;
             }
         }
  });
  $('.country-select2-single').select2({
       theme: 'bootstrap',
        width: 'resolve' ,
       minimumResultsForSearch : -1,
       placeholder: {
           id: '-1', // the value of the option
           text: txt_lieux
         },
         language: {
             "noResults": function(){
                 return txt_noresult;
             }
             
         }
  });

  $('.depart-select2').select2({
       theme: 'bootstrap',
        width: 'resolve' ,
       minimumResultsForSearch : -1,
          language: {
              "noResults": function(){
                  return txt_noresult;
              }
          }
  });

//   $('.datepicker').datepicker({
//       //maxViewMode: 3,
//       defaultViewDate: '01/01/2000',
//       format : 'dd/mm/yyyy',
//       //startDate: '-70Y',
//       startDate: '01/01/1970',
//       endDate: '31/12/2005',
      
//   });

  //check password repeat
   //var showPasswordMatchSuccess = false ;
   //var showPasswordMatchWarning = false ;
 

   $('#candidats_password, #candidats_password2').on('keyup', function(e){

      var pass = $('#candidats_password'); 
      var pass2 = $('#candidats_password2');
e      
      if(pass.val() != pass2.val()){
         $('.password-match').hide();
         $('.password-unmatch').show();
         pass.removeClass('is-valid').addClass('is-invalid') ;
         pass2.removeClass('is-valid').addClass('is-invalid') ;
      }
      else if(pass.val() == pass2.val()){ 
         $('.password-match').show();
         $('.password-unmatch').hide();
         pass.removeClass('is-invalid').addClass('is-valid') ;
         pass2.removeClass('is-invalid').addClass('is-valid') ;
      }

  });

  /*
      Fullscreen background
  */
  // $.backstretch("assets/img/backgrounds/1.jpg");
  
  $('#top-navbar-1').on('shown.bs.collapse', function(){
    $.backstretch("resize");
  });
  $('#top-navbar-1').on('hidden.bs.collapse', function(){
    $.backstretch("resize");
  });

  
  // submit
  // $('.registration-form').on('submit', function(e) {
    
  // 	$(this).find('input[type="text"], input[type="password"], input[type="number"], input[type="email"], select, textarea').each(function() {
  // 		if( $(this).val() == "" ) {
  // 			e.preventDefault();
  // 			$(this).addClass('input-error');
  // 		}
  // 		else {
  // 			$(this).removeClass('input-error');
  // 		}
  // 	});
    
  // });


  //ajax wallie

  $('.toggle-lost-password').on('click', function(){

      var parents = $(this).parents('form') ;
      var next_form = $('form.lost-password') ;
      $('.socials-connect').hide();

      parents.fadeOut(400, function() {
          next_form.fadeIn();
      });

  });

  $('.back-to-login').on('click', function(){

      var parents = $(this).parents('form') ;
      var prev_form = $('form.registration-form') ;

      parents.fadeOut(400, function() {
          prev_form.fadeIn();
      });

  });

  $('.lost-password').on('submit', function(e) {
          
          //var ajax_action = $(this).find('.btn-ajax').attr('data-ajax-action');
         var ajax_action = 'send_password' ;
          var email_for_forgotten_password = $(this).find('.email_for_forgotten_password') ;

          var email = $(this).find('.email_for_forgotten_password').val();

          var type= $(this).data('type') || 'candidat' ;

         // var message = (''!=email) ? '<div class="message success">Un email contenant un lien permettant de réinitialiser votre mot de passe vient de vous être envoyé.</div>' : '<div class="message error">Veuillez renseigner un email valide svp.</div>';

          $.get( "../ajax.php", { ajax_action: ajax_action, email: email, type: type } )
            .done(function( data ) {
             // console.log('ccou')
              $('#result').html(data);
            })
            .fail(function(xhr, status, error) {
                  alert("An AJAX error occured: " + status + "\nError: " + error + "\nError detail: " + xhr.responseText );
                }); 

      e.preventDefault();
  });


  //opérateurs par type (skype ou classique)
  $(document).on('change', 'select[name="operateur-type"]', function(){
      var type = $(this).find('option:selected').val();
       $.get( "../ajax.php", { ajax_action: "operateurs_per_type", type: type } )
            .done(function( data ) {
              
              reset_select() ; //reset
              reset_calendar();
             // var operateurs = JSON.parse(data); //json reponse en object
              var operateurs = data; //json reponse en object
             
              for(var op in operateurs){
                  //console.log(op);
                  var option = '<option value="'+operateurs[op].id+'">'+ operateurs[op].ville ;
                  if(operateurs[op].structure.length > 0) option +=' ('+operateurs[op].structure+')';
                  option +='</option>';
                  $('select[name="operateur"]').append(option).trigger('change');
              }
            
              $('.choose-op').removeClass('d-none');
            }, 
            function(){
             

               $(document).on('change', 'select[name="operateur"]', function(){
                      var operateur = $(this).find('option:selected').val();
                      get_operateur_calendar(operateur);
                     
              });
            }); 
  });

  $(document).on('change', 'select[name="operateurs_zones"]', function(){
      var zone_id = $(this).find('option:selected').val();
    $.post('ajax-controller.php', {
        get_operateurs_by_zone : true,
        zone_id : zone_id,
      })
      .done(function( res ) {
        
        $('.form-group-operateurs').removeClass('d-none');
        $('select[name="operateur"]').html(res.html);
       
      });

      return false; 
   
   
});

  $(document).on('change', 'select[name="operateur"]', function(){
    var operateur = $(this).find('option:selected').val();
    get_operateur_calendar(operateur);
   
});

  /**
   * chope liste des régions par pays
   */
  $(document).on('change', 'select[name="candidats_pays"]', function(){
      var country_id = $(this).find('option:selected').val();
      console.log(country_id);
      var locale = $(this).data('locale');
       $.get( "../ajax.php", { ajax_action: "liste_regions", country_id: country_id , locale: locale } )
            .done(function( data ) {
              
              var regions = data; //json reponse en object
             
              for(var region in regions){
                  var option = '<option value="'+regions[region].id+'">'+ regions[region].titre +'</option>';
                  $('select#candidats_regions').append(option).trigger('change');
              }
            
              $('#display_candidats_regions').addClass('in');
            }); 
  });

  $(document).on('change', 'select[name="offres_type"]', function(){
      var offres_type = $(this).find('option:selected').val();
      if(offres_type=="permanente"){
          $('#display_offres_date_debut, #display_offres_date_fin').addClass('d-none');
      }
      else{
          $('#display_offres_date_debut, #display_offres_date_fin').removeClass('d-none');
      }
      
  });

  $(window).on('load', function(){
      if($('select[name="offres_type"]').find('option:selected').val() == 'permanente'){
          $('#display_offres_date_debut, #display_offres_date_fin').addClass('d-none');
          $('#offres_date_debut').prop('required', false);
          $('#offres_date_fin').prop('required', false);
      }
  });


  function getUrlVars(url){
      var vars = [], hash;
      var hashes = url.slice(window.location.href.indexOf('?') + 1).split('&');
      for(var i = 0; i < hashes.length; i++)
      {
          hash = hashes[i].split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
      }
      return vars;
  }


  function reset_select(){
        $('.choose-hour').addClass('d-none'); // cache select creneau
        //$('select[name="operateur-type"] option:enabled').remove(); // vide le select
        $('select[name="operateur"] option:enabled').remove(); // vide le select
  }

  function reset_calendar(){
        $('#calendar')
          .addClass('d-none')
          .fullCalendar('destroy');
        $('.no-events').addClass('d-none');
       // $('.display-with-calendar').addClass('d-none');
  }
  //ajax operateur > creneau
  function get_operateur_calendar(operateur){
       var events;
       $.getJSON('../ajax.php?ajax_action=month_dispo_per_operateur&operateur_id='+operateur, function(data){
          events = data;

         if(data == 'Aucun créneau disponible pour ce conseiller'){ //pas de dispo : message + return
              $('.no-events').removeClass('d-none');
              $('#calendar').addClass('d-none');
              return ;
          }


          reset_calendar() ;
          $('.display-with-calendar').removeClass('d-none')
          $('#calendar')
                  .removeClass('d-none')
                  .fullCalendar({
                       header: {
                           left: 'prev,next',
                           center: 'title',
                            right: 'month,agendaWeek,agendaDay'
                      },
                      minTime : '08:00' ,
                      maxTime :'19:00' ,
                      weekends: false,
                      allDaySlot: false,
                      buttonText: {
                         today: buttonText.today[lang],
                         month: buttonText.month[lang],
                         week: buttonText.week[lang],
                         day: buttonText.day[lang]
                       },
                      defaultView: 'month',
                      events: events,
                      eventClick: function(event) {
                          console.log(event);
                          if (event.url) {
                               var url = event.url ;

                               console.log(url);
                               console.log( getUrlVars(url)["dispo"]);

                                  var creneau = getUrlVars(url)["dispo"];
                                  var displayedCreneau = creneau.split('-');
                                  console.log("creneau : "+creneau);
                                  $('input[name="creneau"]').val(creneau);
                                  $('input[name="operateur"]').val(operateur);
                                  $('.choosen-crenau').removeClass('d-none') ;
                                  $('.choosen-op span').addClass('d-none') ;
                                  $('.choose-operateur-wrap').addClass('d-none') ;

                                  $('.choosen-crenau p strong').html('le '+displayedCreneau[0]+'/'+displayedCreneau[1]+'/'+displayedCreneau[2]+' de '+displayedCreneau[3]+':'+displayedCreneau[4]+' à '+displayedCreneau[5]+':'+displayedCreneau[6]) ;
                                  $('.final-step').removeClass('d-none');

                                  //check si c'est un skype
                                  //pour afficher champs facultatif id skype
                                  //lors de l'enregistrement du RDV
                                    $.get( "../ajax.php", { ajax_action: "operateur_data", operateur: operateur} )
                                           .done(function( data ) {
                                              //op = JSON.parse(data) ;
                                              var op = data ;
                                             // console.log(op[operateur].type_valid);
                                              if(op[operateur].type_valid == "skype"){
                                                  $('.skype-id').removeClass('d-none');
                                                  $('#skype_id').attr('required', 'required');
                                              }
                                              else{
                                                  $('#skype_id').removeAttr('required');
                                              }
                                           });
                                  reset_calendar();
                          }
                          //event.preventDefault();
                          return false ;
                      },
                      editable: false,
                      droppable: false,
                      dayRender: function(date, cell){
                          var now = new Date(); 
                          if (date < now){
                              $(cell).addClass('disabled');
                          }
                      }
                   });

                  $('#calendar').fullCalendar("refetchEvents") ;
          });
  }


  function get_full_calendar(){
      var events;
      $.getJSON('../ajax.php?ajax_action=get_full_dispo', function(data){
         events = data;

        if(data == 'Aucun créneau disponible pour ce conseiller'){ //pas de dispo : message + return
             $('.no-events').removeClass('d-none');
             return ;
         }

         reset_calendar() ;
         $('.display-with-calendar').removeClass('d-none')
         $('#calendar')
                 .removeClass('d-none')
                 .fullCalendar({
                      header: {
                          left: 'prev,next',
                          center: 'title',
                           right: 'month,agendaWeek,agendaDay'
                     },
                     minTime : '08:00' ,
                     maxTime :'19:00' ,
                     weekends: false,
                     allDaySlot: false,
                     buttonText: {
                        today: buttonText.today[lang],
                        month: buttonText.month[lang],
                        week: buttonText.week[lang],
                       day: buttonText.day[lang]
                      },
                     defaultView: 'month',
                     events: events,
                     eventClick: function(event) {
                         if (event.url) {
                              var url = event.url ;

                                 var creneau = getUrlVars(url)["dispo"];
                                 var operateur = getUrlVars(url)["operateur"];
                                 var displayedCreneau = creneau.split('-');
                                 console.log("creneau : "+creneau);
                                 console.log("operateur : "+operateur);
                                 $('input[name="creneau"]').val(creneau);
                                 $('input[name="operateur"]').val(operateur);
                                 $('.choosen-crenau').removeClass('d-none') ;
                                 $('.choose-operateur-wrap').addClass('d-none') ;
                                 $('.choosen-op span').addClass('d-none') ;
                                 $('.display-with-calendar').addClass('d-none') ;

                                 $('.choosen-crenau p strong').html('le '+displayedCreneau[0]+'/'+displayedCreneau[1]+'/'+displayedCreneau[2]+' de '+displayedCreneau[3]+':'+displayedCreneau[4]+' à '+displayedCreneau[5]+':'+displayedCreneau[6]) ;
                                 $('.final-step').removeClass('d-none');

                                 //check si c'est un skype
                                 //pour afficher champs facultatif id skype
                                 //lors de l'enregistrement du RDV
                                  //  $.get( "../ajax.php", { ajax_action: "operateur_data", operateur: operateur} )
                                  //         .done(function( data ) {
                                  //            //op = JSON.parse(data) ;
                                  //            op = data ;
                                  //           // console.log(op[operateur].type_valid);
                                  //            if(op[operateur].type_valid == "skype"){
                                  //                $('.skype-id').removeClass('d-none');
                                  //                $('#skype_id').attr('required', 'required');
                                  //            }
                                  //            else{
                                  //                $('#skype_id').removeAttr('required');
                                  //            }
                                  //         });
                                 reset_calendar();
                                 return false ;
                         }
                         //event.preventDefault();
                         return false ;
                     },
                     editable: false,
                     droppable: false,
                     dayRender: function(date, cell){
                         var now = new Date(); 
                         if (date < now){
                             $(cell).addClass('disabled');
                         }
                     }
                  });

                 $('#calendar').fullCalendar("refetchEvents") ;
         });
 }


//  if($('#calendar').length > 0){
//    get_full_calendar();
//  }

  //cancel rdv
  $('.cancel-rdv').on('click', function(e) {
      if (confirm('Es-tu sûr(e) de vouloir annuler ce rendez-vous ?')) {

          var ajax_action = $(this).attr('data-ajax-action');
          var ape_id = $(this).attr('data-ape-id');
          var user_id = $(this).attr('data-user-id');


          $.get( "../ajax.php", { ajax_action: ajax_action, ape_id: ape_id , user_id: user_id } )
            .done(function( data ) {
              var ape_id = data ;
              console.log(ape_id)
              console.log(user_id)
              $('.cancel-rdv[data-ape-id="'+ape_id+'"]')
                  .parents('.list-group-item')
                  .fadeOut('slow', function(){
                      $(this).remove();
                      var new_rdv_count = parseInt($('.nb_rdv').text()) - 1 ;
                      var total_rdv_count = parseInt($('.nb_rdv_total').text())  ;
                      $('.nb_rdv').text( new_rdv_count );

                      //reload page si nbre de rdv = 0 (pour réficher bouton "prendre un rdv")
                      if(new_rdv_count == total_rdv_count) window.location.reload();
                  })
            }); 
      }

      e.preventDefault();

  });


  //cancel rdv
  $('.cancel-offre').on('click', function(e) {
      if (confirm('Confirmez-vous la suppression de cette offre ?')) {

          var ajax_action = $(this).attr('data-ajax-action');
          var offre_id = $(this).attr('data-offre-id');
          var organisme_id = $(this).attr('data-organisme-id');


          $.get( "../ajax.php", { ajax_action: ajax_action, offre_id: offre_id, organisme_id: organisme_id } )
            .done(function( data ) {
              var offre_id = data ;
              console.log(offre_id)
              $('.cancel-offre[data-offre-id="'+offre_id+'"]')
                  .parents('.list-group-item')
                  .fadeOut('slow', function(){
                      $(this).remove();
                       $('.nb_offres').text( parseInt($('.nb_offres').text()) -1 );
                  })
            }); 
      }

      e.preventDefault();

  });

  //switch offre status
  $(document).on('click', '.switch-offre-status', function(e) {
      if (confirm('Êtes-vous sûr(e) de vouloir modifier le statut cette offre ?')) {

          var ajax_action = $(this).attr('data-ajax-action');
          var offre_id = $(this).attr('data-offre-id');
          var offre_status = $(this).attr('data-offre-status');
          var organisme_id = $(this).attr('data-organisme-id');


          $.get( "../ajax.php", { ajax_action: ajax_action, offre_id: offre_id, offre_status: offre_status, organisme_id: organisme_id } )
            .done(function( data ) {
                  $('.switch-offre-status[data-offre-id="'+offre_id+'"]').html(data.response);
                  $('.switch-offre-status[data-offre-id="'+offre_id+'"]').attr("data-offre-status", data.status);
                 $('.active-text-'+offre_id).text(data.active_text);
             
            })
             .fail(function(xhr, status, error) {
                  console.log("An AJAX error occured: " + status + "\nError: " + error + "\nError detail: " + xhr.responseText );
                }); 
      }

      e.preventDefault();

  });


//clone rdv
  $('.clone-offre').on('click', function(e) {
      if (confirm('Êtes-vous sûr(e) de vouloir dupliquer cette offre ?')) {

          var ajax_action = $(this).attr('data-ajax-action');
          var offre_id = $(this).attr('data-offre-id');
          var organisme_id = $(this).attr('data-organisme-id');


          $.get( "../ajax.php", { ajax_action: ajax_action, offre_id: offre_id, organisme_id: organisme_id } )
            .done(function( data ) {
              var offre_id = data ;
              console.log(offre_id)
              window.location.reload();
            }); 
      }

      e.preventDefault();

  });

  setTimeout(function(){
      $('.updated-badge').show('slow', function(){
           setTimeout(function(){
               $('.updated-badge').hide('slow') ;
           }, 1500);
      });
  }, 400);

  

 $(document).on('click', '.map-choose-op', function(){

      var op = $(this).attr('data-op') ;
      var opdisplayname = $(this).attr('data-op-display-name') ;
      //console.log(op);

      //cache google map
      $('.rdv-choice-wrap').toggleClass('d-none') ;
      //affiche vous avez choisi xx
      $('.choosen-op').toggleClass('d-none');
      $('.choosen-op').append('<input name="operateur" type="hidden" value="'+op+'">');
      $('.choosen-op strong').html(opdisplayname);

      get_operateur_calendar(op);
      return false ;
 });

 $(document).on('change', '.op-skype', function(){

      var op =  $(this).find('option:selected').val();

      if( $.isNumeric(op)){
           var opdisplayname = $(this).find('option:selected').text() ;
          //console.log(op);

          //cache google map
          $('.rdv-choice-wrap').toggleClass('d-none') ;
          //affiche vous avez choisi xx
          $('.choosen-op').toggleClass('d-none');
          $('.choosen-op').append('<input name="operateur" type="hidden" value="'+op+'">');
          $('.choosen-op strong').html(opdisplayname);

          get_operateur_calendar(op);
      }
     
 });

 $('.op-skype').change();

 $(document).on('click', '.reinit-rdv', function(){
      $('.final-step').addClass('d-none') ;
      
      $('.choosen-op').addClass('d-none') ;
      $('.choosen-crenau').addClass('d-none') ;
      $('.choose-operateur-wrap').removeClass('d-none') ;
      $('#calendar').removeClass('d-none') ;
      $('.display-with-calendar').removeClass('d-none') ;
      get_full_calendar();
       $('.skype-id').addClass('d-none');

      $('.rdv-choice-wrap').removeClass('d-none') ;

      $('.op-skype option:first').prop('selected', true) ;
     // $('.op-skype option:selected').prop('selected', false) ;


     
      return false ;

 });

 
 
  var gmapIsReady = false;
  var geometry ;

          //$('body').hover(function () {
              if (!gmapIsReady && $('#google-map-ops').length > 0) {
                  initGmap();
              }
             // $('#gm-panel').slideToggle('slow');
         // });


           function initGmap() {
              gmapIsReady = true;
             
             
              var geocoder ;
              var styles = [
                  {
                      stylers: [
                          {saturation: -100}
                      ]
                  }, {
                      featureType: "road",
                      elementType: "geometry",
                      stylers: [
                          {lightness: 100},
                          {visibility: "simplified"}
                      ]
                  }, {
                      featureType: "road",
                      elementType: "labels",
                      stylers: [
                          {visibility: "off"}
                      ]
                  }
              ];

              
               
              var styledMap = new google.maps.StyledMapType(styles, {name: "Styled Map"});

              var map_center_lat =  user_map_lat ||  44.844451 ;
              var map_center_long =  user_map_long ||  0.653711 ;

              var $latlng = new google.maps.LatLng(map_center_lat, user_map_long),
                  $mapOptions = {
                      zoom: 7,
                      center: $latlng,
                      panControl: false,
                      zoomControl: true,
                      scaleControl: false,
                      mapTypeControl: false,
                      scrollwheel: false,
                      mapTypeControlOptions: {
                          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                      }
                  };
              var map = new google.maps.Map(document.getElementById('google-map-ops'), $mapOptions);

              google.maps.event.trigger(map, 'resize');

              map.mapTypes.set('map_style', styledMap);
              map.setMapTypeId('map_style');

              var location = [];
              var locations = [];
              var infoWindows = [];
              var image = '//gopy.eu/assets/custom/images/marker.png';
              var markerCluster = new MarkerClusterer(map, '',{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

              markerCluster.setGridSize(50);


              function foo(op, fn){

                  var adr1 = op['adr1'].split(' ').join('+') ;
                              var adr2 = op['adr2'].split(' ').join('+') ;
                              var ville = op['ville'].split(' ').join('+') ;
                              var address = adr1+','+ville ;

                              var infowindowContent = [
                                  '<b>'+op['ville']+' ('+op['structure']+')</b><br>'+op['adr1']+'<br>'+op['cp']+' '+op['ville']+'<br><br><button class="map-choose-op btn btn-xs btn-success" data-op="'+op['id']+'" data-op-display-name="'+ op['ville']+' ('+op['structure']+')">Prendre rendez-vous</button>',
                                  op['type'],
                                  op['ville']+' ('+op['structure']+')'
                              ];
               geocoder = new google.maps.Geocoder();
                geocoder.geocode( { 'address': address}, function(results, status) {
                  if(null !=results && results.length > 0)
                      fn(results[0].geometry.location, infowindowContent[2], infowindowContent[0]); 
                });
              }

              function closeAllInfoWindows() {
                        for (var i=0;i<infoWindows.length;i++) {
                           infoWindows[i].close();
                        }
                      }

             
              
              var scriptUrl = '../ajax.php?ajax_action=liste_operateurs';
              $.ajax({
                      url: scriptUrl,
                      type: 'get',
                      dataType: 'json',
                      success: function(data) {
                          for(var op in data){
                              

                               foo(data[op], function(location, title, content){
                                  var marker = new google.maps.Marker({
                                          position: location,
                                          map: map, 
                                          title:title,
                                          icon : image
                                      });

                                  markerCluster.addMarker(marker);


                                  var infowindow = new google.maps.InfoWindow({
                                      content: content
                                  });


                                        marker.addListener('click', function() {
                                          closeAllInfoWindows();
                                          infowindow.open(map, marker);
                                          infoWindows.push(infowindow);
                                        });
                                   });

                          }
                      }
              });


              
          }



         /*
          function initGmap() {
              gmapIsReady = true;
             
             

              // Create an array of styles.
              var styles = [
                  {
                      stylers: [
                          {saturation: -100}
                      ]
                  }, {
                      featureType: "road",
                      elementType: "geometry",
                      stylers: [
                          {lightness: 100},
                          {visibility: "simplified"}
                      ]
                  }, {
                      featureType: "road",
                      elementType: "labels",
                      stylers: [
                          {visibility: "off"}
                      ]
                  }
              ];

              var locations = [];
               
              var styledMap = new google.maps.StyledMapType(styles, {name: "Styled Map"});

              var map_center_lat =  user_map_lat ||  44.844451 ;
              var map_center_long =  user_map_long ||  0.653711 ;

              var $latlng = new google.maps.LatLng(map_center_lat, user_map_long),
                  $mapOptions = {
                      zoom: 7,
                      center: $latlng,
                      panControl: false,
                      zoomControl: true,
                      scaleControl: false,
                      mapTypeControl: false,
                      scrollwheel: false,
                      mapTypeControlOptions: {
                          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                      }
                  };
              var map = new google.maps.Map(document.getElementById('google-map-ops'), $mapOptions);

              google.maps.event.trigger(map, 'resize');

              map.mapTypes.set('map_style', styledMap);
              map.setMapTypeId('map_style');

            
              $.get( "../ajax.php", { ajax_action: 'liste_operateurs' } )
                   .done(function( data ) {
                  
                   try{
                       var count = 0 ;
                       var infoWindows = [];
                      
                       var response = data;

                       function closeAllInfoWindows() {
                        for (var i=0;i<infoWindows.length;i++) {
                           infoWindows[i].close();
                        }
                      }
                

                       for(var op in response){

                          var adr1 = response[op]['adr1'].split(' ').join('+') ;
                          var adr2 = response[op]['adr2'].split(' ').join('+') ;
                          var ville = response[op]['ville'].split(' ').join('+') ;
                          var address = adr1+','+ville ;


                         
                      
                          var infowindowContent = [
                              '<b>'+response[op]['ville']+' ('+response[op]['structure']+')</b><br>'+response[op]['adr1']+'<br>'+response[op]['cp']+' '+response[op]['ville']+'<br><br><button class="map-choose-op btn btn-xs btn-success" data-op="'+op+'" data-op-display-name="'+ response[op]['ville']+' ('+response[op]['structure']+')">Prendre rendez-vous</button>',
                              response[op]['type'],
                              response[op]['ville']+' ('+response[op]['structure']+')'
                          ];

                          var markers = [];

                          //closure, voir http://stackoverflow.com/questions/939032/jquery-pass-more-parameters-into-callback
                          var drawMarkers = function(infowindowContent) {
                              return function(data, textStatus, jqXHR) {

                                    var bounds = new google.maps.LatLngBounds();
                                    // do something with extraStuff
                                    var lat = data.results[0].geometry.location.lat ;
                                    var lng = data.results[0].geometry.location.lng ;
                                    var image = '//gopy.eu/assets/custom/images/marker.png';
                             

                                    var marker = new google.maps.Marker({
                                      position: new google.maps.LatLng(lat, lng),
                                      map: map, 
                                      title:infowindowContent[2],
                                      icon : image
                                    });

                                    bounds.extend(marker.position);

                              
                                    var infowindow = new google.maps.InfoWindow({
                                              content: infowindowContent[0]
                                    });
                                    marker.addListener('click', function() {
                                      closeAllInfoWindows();
                                      infowindow.open(map, marker);
                                      infoWindows.push(infowindow);
                                    });


                                    markers.push(marker);

                                    // Add a marker clusterer to manage the markers.
                                      // var markerCluster = new MarkerClusterer(map, markers,
                                      //     {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

                                      // console.log(markerCluster);
                                   
                              };
                          };
                            
                     
                           $.get('https://maps.googleapis.com/maps/api/geocode/json?address='+address, drawMarkers(infowindowContent)) ;
                           // map.fitBounds(bounds);

                         
                            count++ ;  
                    
                      }

                       //console.log(markers);
                   


                     

                   }
                   catch (e) {
                    console.error("Erreur en parsant la liste des opérateurs disponibles :", e); 
                  }


                 
                   
                 }); 

              
          }

          */

  
  /* Apparition email accompagnant dans espace candidat */

  $(document).on('change', 'input[name="candidats_accompagnant"]', function(){
      if (this.value =='Oui'){
          $('.email_accompagnant').removeClass('d-none');
      }
      else if (this.value == 'Non'){
          $('.email_accompagnant').addClass('d-none');
      }

  })

  /* Envoi email accompagnant */

   $(document).on('submit', '#formulaire_accompagnant', function(){
      var email_accompagnant = $(this).find('input[type="email"]').val();
      var static_txt = $('label[for="candidats_accompagnant"]').text();

       $.get( "../ajax.php", { ajax_action: 'set_candidat_accompagnant',email_accompagnant: email_accompagnant , user_id: user_id} )
            .done(function( data ) {
              if (data.status == 'success'){
                  $('#formulaire_accompagnant').addClass('d-none');
                  $('#email_accompagnant_ok').find('span').html(data.email_accompagnant);
                  $('#email_accompagnant_ok').removeClass('d-none');

              }
              if (data.status == 'error') 
              {
                  var affichage = '<div class="alert alert-danger" role="alert"><p>'+data.msg_error+'</p></div>';
                  $('#formulaire_accompagnant').before(affichage);
              }

            }); 

            return false;
  });

  $(document).on('click', '#delete_email', function (){
      

      $.get("../ajax.php", { ajax_action: 'delete_candidat_accompagnant', user_id: user_id })
          .done(function(data){
              console.log(data);
              if (data.status == 'success'){
                  $('#formulaire_accompagnant').removeClass('d-none');
                  $('#email_accompagnant_ok').addClass('d-none');
                  $('input[type="email"]').val('');
                  
                  $('.candidats_accompagnant_non').prop('checked', true).trigger('change');

              }
          }); 
  });

  $(document).on('click', '.delete-annonce', function (){

    if(confirm('Confirmer la suppression?')){

        var annonce_id = $(this).data('id') ;
        var user_id = $(this).data('user-id') ;
        var organisme_id = $(this).data('organisme-id') ;

        $.get("../ajax.php", { 
            ajax_action: 'delete_annonce', 
            annonce_id: annonce_id ,
            user_id: user_id ,
            organisme_id: organisme_id 
            })
            .done(function(data){
                console.log(data);
                if (data.status == true){
                    $('#annonce-'+annonce_id).remove();
                }
            }); 
    }
  });


  $(document).on('change', 'input[name="type"]', function(){
      let value = $(this).filter(':checked').val();
      console.log(value);
      if(value == 'offre'){
          $('.offre-form').removeClass('d-none');
          // $('.offre-form').addClass('d-flex');

          $('.dispositif-form').addClass('d-none');
          // $('.dispositif-form').removeClass('d-flex');

          $('.offre-form').find('.form-control').prop('disabled', false);
          $('.dispositif-form').find('.form-control').prop('disabled', true);
      }
      else if(value == 'dispositif'){
          $('.dispositif-form').removeClass('d-none');
          // $('.dispositif-form').addClass('d-flex');

          $('.offre-form').addClass('d-none');
          // $('.offre-form').removeClass('d-flex');

          $('.offre-form').find('.form-control').prop('disabled', true);
          $('.dispositif-form').find('.form-control').prop('disabled', false);
      }
  });

  $('input[name="type"]').change();

  $(".checkbox-menu").on("change", "input[type='checkbox']", function() {
      $(this).closest("li").toggleClass("active", this.checked);
   });
   
   $(document).on('click', '.allow-focus', function (e) {
     e.stopPropagation();
   });



  
});



      /* Google Analytics */
  (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () {(i[r].q = i[r].q || []).push(arguments)};
      i[r].l = 1 * new Date();
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
  })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

  ga('create', 'UA-40696437-12', 'auto');
  ga('send', 'pageview');


function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}


function validatePhone(phone)  
{  
var valid_phone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;  
if(phone.match(valid_phone)){  
    return true;  
}  
else{  
  return false;  
}  
} 

var mySwiper = new Swiper ('.swiper-offres-une', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  autoHeight: true,
  speed: 800,
  autoplay: {
      delay: 8000,
    },

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar1',
  },
});


$("#presentation-slider-modal").on('show.bs.modal', function() {
  setTimeout(function() {
      var mySwiper2 = new Swiper ('.swiper-presentation', {
          // Optional parameters
          direction: 'horizontal',
          loop: false,
          autoHeight: true,
          speed: 800,
          autoplay: false,
      
          // If we need pagination
          pagination: {
            el: '.swiper-pagination2',
          },
      
          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next2',
            prevEl: '.swiper-button-prev2',
          },
      
          // And if we need scrollbar
          // scrollbar: {
          //   el: '.swiper-scrollbar2',
          // },
        });
  }, 500);
});



function resetModal(){
    $('genericModal').find('.modal-title').text('');
    $('genericModal').find('.modal-body').html('');
}


if($('.navbar.fixed-top').length > 0){
  var logoSrc = $('.navbar.fixed-top').find('.brand-logo').attr('src') ;
  var logoSrcDark = $('.navbar.fixed-top').find('.brand-logo').attr('src').replace('-white', '') ;

  $(window).scroll(function() {
      if ($(document).scrollTop() > 100) {
          $('.navbar.fixed-top').addClass('bg-light');
          $('.brand-logo').attr('src', logoSrcDark);
      }
      else {
          $('.navbar.fixed-top').removeClass('bg-light');
          $('.brand-logo').attr('src', logoSrc);
      }
  });
}







